<template>
  <div class="px-1">
    <v-row class="mt-0 pt-0">
      <v-col
        cols="12"
        :md="12"
        class="mt-0 pt-0"
      >
        <logs-toolbar
          :filter-keyword.sync="filterSearch"
          :filter-level.sync="filterLevel"
          v-on:reload-items="refreshLogs"
        />

        <log-table
          ref="logTable"
          :filter-search.sync="filterSearchUpdate"
          :filters.sync="logsFilters"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LogsToolbar from '@/components/toolbar/LogToolbar.vue'
import LogTable from '@/components/table/LogTable.vue'

export default {
  name: 'Logs',
  metaInfo: {
    title: 'Logs'
  },
  components: {
    LogTable,
    LogsToolbar
  },
  data: () => ({
    filterSearch: null,
    filterLevel: null
  }),
  mounted() {
    this.$store.dispatch('changeThemeLoadingState', false)
  },
  computed: {
    logsFilters() {
      return {
        level: this.filterLevel
      }
    },
    filterSearchUpdate() {
      if (this.filterSearch !== null) {
        this.$refs.logTable.searchAfterWait()
        return this.filterSearch
      }
    }
  },
  methods: {
    refreshLogs() {
      this.$refs.logTable.fetchLogs()
    },
    performRetest() {
      this.dialog = false
    },
    clearFilter() {
      this.filterSearch = ''
      this.filterLevel = null
    }
  }
}
</script>
<style lang="scss" scoped>
table.v-table tbody td,
table.v-table tbody th {
  min-height: 50px !important;
}

.v-stepper,
.v-stepper__header {
  box-shadow: none !important;
}
</style>
