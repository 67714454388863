<template>
  <div>
    <base-toolbar>
      <template v-slot:icon>mdi-history</template>
      <template v-slot:filters>
        <!--<v-text-field
          hide-details
          label="Search"
          :value="filterKeyword"
          placeholder="Search"
          solo
          dense
          single-line
          style="width: 500px; min-width: 300px"
          append-icon="mdi-magnify"
          class="shrink mx-2"
          @input="$emit('update:filter-keyword', $event)"
        />-->
        <v-col cols="7" sm="3" md="2" lg="2" class="pl-0 pl-sm-3">
          <v-select
            label="Level"
            :value="filterLevel"
            :items="loglevel"
            solo
            flat
            background-color="grey"
            dense
            single-line
            hide-details
            class="shrink"
            @input="$emit('update:filter-level', $event)"
          />
        </v-col>
        <v-tooltip bottom v-if="showClearFilterButton">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey"
              class="mx-2"
              fab
              small
              :elevation="0"
              @click="clearFilter"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error">mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Clear Filters</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              elevation="0"
              color="grey"
              class="ml-2"
              :loading="isLoading"
              v-bind="attrs"
              v-on="on"
              @click="updatePage"
            >
              <v-icon color="primary">mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>Reload</span>
        </v-tooltip>
      </template>
    </base-toolbar>
  </div>
</template>

<script>
import BaseToolbar from '@/components/toolbar/BaseToolbar'
import { loglevel } from '@/data/common'

export default {
  components: {
    BaseToolbar
  },
  props: {
    filterKeyword: {},
    filterLevel: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      loglevel,
      isLoading: false
    }
  },
  computed: {
    showClearFilterButton() {
      return !!(this.filterKeyword || this.filterLevel)
    }
  },
  methods: {
    clearFilter() {
      this.$emit('update:filter-keyword', '')
      this.$emit('update:filter-level', null)
    },
    updatePage() {
      this.isLoading = true
      setTimeout(() => {
        this.$emit('reload-items')
        this.isLoading = false
      }, 3000)
    }
  }
}
</script>
