import api from './api'
import { paginate } from '@/services/utils'

class LogsService {
  async getLogs(page, perPage, search, filters) {
    const requestUrl = paginate('api/v1/logs/', page, perPage, search, filters)
    const response = await api.get(requestUrl)
    return response.data
  }
}

export default new LogsService()
