<template>
  <div>
    <v-card flat>
      <v-data-table
        :headers="headers"
        :items="logs"
        :server-items-length="logCount"
        :loading="isLoading"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: pageSizeOptions }"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-history
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.name="{ item }">
          <h6 v-if="item.isDeleted" class="error--text">{{ item.name }}</h6>
          <h6 v-else>{{ item.name }}</h6>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <div>
            {{ displayDate(item) }} {{ displayTime(item) }}
          </div>
        </template>

        <template v-slot:item.level="{ item }">
          <v-chip
            v-if="item.level === 'INFO'"
            color="info"
            outlined
          >
            Info
          </v-chip>
          <v-chip
            v-if="item.level === 'WARNING'"
            color="warning"
            outlined
          >
            Warning
          </v-chip>
          <v-chip
            v-if="item.level === 'CRITICAL'"
            color="error"
            outlined
          >
            Critical
          </v-chip>
          <v-chip
            v-if="item.level === 'ERROR'"
            color="error darken-2"
            outlined
          >
            Error
          </v-chip>
        </template>

        <template v-slot:item.pentestCount="{ item }">
          <v-chip color="success" outlined>{{ item.pentestCount }} Pentest</v-chip>
        </template>
      </v-data-table>
      <!--<template>
        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-pagination
                v-model="page"
                :length="Math.ceil(countItem / itemsPerPage)"
                :total-visible="7"
                class="btnPagination"
                @input="updatePage"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </template>-->
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import LogsService from '@/services/logs.service'

export default {
  components: {
  },
  props: {
    filterSearch: {
      type: String || undefined,
      default: ''
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      logs: [],
      logCount: 0,
      pageSizeOptions: [10, 25, 50],
      search: this.$route.query.search || '',
      isLoading: false,
      debounceSearch: null,
      options: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '10', 10) || 10,
        search: this.$route.query.search || '',
        level: this.$route.query.level || ''
      },
      previousCall: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '10', 10) || 10,
        search: this.$route.query.search || '',
        level: this.$route.query.level || ''
      },
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'icon',
          width: '20px',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Date/Time',
          value: 'createdAt',
          width: '200px',
          class: 'text-left font-weight-medium subtitle-1'
        },
        {
          text: 'Level',
          value: 'level',
          sortable: false,
          width: '130px',
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Event',
          value: 'msg',
          sortable: false,
          align: 'left',
          class: 'text-left font-weight-medium subtitle-1'
        },
        {
          text: 'Source IP',
          value: 'ip',
          sortable: false,
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'User',
          value: 'user.fullName',
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchLogsHandler()
      },
      deep: true
    },
    filters: {
      handler() {
        this.options.level = this.filters.level
      },
      deep: true
    }
  },
  mounted() {
    this.fetchLogs()
  },
  methods: {
    searchAfterWait() {
      this.search = this.filterSearch
      if (!this.debounceSearch) {
        this.debounceSearch = _.debounce(this.fetchLogsHandler, 700)
      }
      this.debounceSearch()
    },
    fetchLogsHandler() {
      const newCall = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.search,
        level: this.options.level
      }
      if (
        newCall.page === this.previousCall.page
        && newCall.itemsPerPage === this.previousCall.itemsPerPage
        && newCall.search === this.previousCall.search
        && newCall.level === this.previousCall.level
      ) {
        return
      }
      if (
        (
          newCall.itemsPerPage !== this.previousCall.itemsPerPage
          || newCall.search !== this.previousCall.search
          || newCall.level !== this.previousCall.level
        ) && this.options.page !== 1
      ) {
        this.options.page = 1 // reset to page 1 will retrigger the call
        return
      }
      this.previousCall = newCall
      this.fetchLogs()
    },
    fetchLogs() {
      this.isLoading = true
      const { page, itemsPerPage, level } = this.options
      LogsService.getLogs(
        page, itemsPerPage, this.search, { level: level }
      ).then((logs) => {
        this.logs = logs.results
        this.logCount = logs.total
      }).catch(() => {})
      this.isLoading = false
    },
    displayDate(item) {
      return `${new Date(item.createdAt.slice(0, 16) + '+0000').toLocaleDateString('fr-CA')}`
    },
    displayTime(item) {
      return `${new Date(item.createdAt.slice(0, 19) + '+0000').toTimeString().slice(0, 9)}`
    }
  }
}
</script>

<style lang="scss">
.v-pagination__item, .v-pagination__navigation {
  box-shadow: 0 0 transparent !important;
}

li button {
  font-size: 0.75rem !important;
font-family: "Archivo", sans-serif !important;
}
</style>
